import { createSharedComposable } from '@vueuse/core'
import type { ListItem } from '~/types'
import { FilterType } from '~/enums'

export const _useFilterTypes = () => {
  const { $i18n } = useNuxtApp()

  return [
    { label: $i18n.t('enum.filter.type.buy'), value: FilterType.Buy, disabled: false },
    { label: $i18n.t('enum.filter.type.rent'), value: FilterType.Rent, disabled: true },
    { label: $i18n.t('enum.filter.type.rent_daily'), value: FilterType.RentDaily, disabled: true },
    { label: $i18n.t('enum.filter.type.rent_hotel'), value: FilterType.RentHotel, disabled: true }
  ] as ListItem[]
}

export const useFilterTypes = createSharedComposable(_useFilterTypes)
