import type { Region } from '~/types'
import { RegionStatus } from '~/enums'
import { useT } from '~/composables/useT'
import type { FilterItem } from '~/types/filter'

interface State {
  items: Region[],
  pending: boolean,
  isLoaded: boolean
}

export const useRegionStore = defineStore('region', {
  state: (): State => {
    return <State>{
      items: [],
      pending: false,
      isLoaded: false
    }
  },
  getters: {
    getOptions: (state: State) => {
      const options: FilterItem[] = state.items.map((item: any) => {
        return {
          value: item.id,
          label: item.title,
          slug: item.slug,
          disabled: item.status !== RegionStatus.Enabled,
          item: item
        }
      })

      if (options.length === 1) {
        options.push({
          value: '-1',
          slug: undefined,
          label: useT('region.other'),
          disabled: true,
          item: undefined
        })
      }

      return options
    }
  },
  actions: {
    async init() {
      const query = {
        'filter[status]': RegionStatus.Enabled,
        sort: 'position',
        perPage: 500
      } as any

      this.pending = true

      const result= await useAPIFetch<{ data: Region[] }>('/v1/regions/search', {
        query
      })

      this.pending = false

      this.items = result.data

      this.isLoaded = true
    },
    search(params?: any){
      return useAPIFetch<{ data: Region[] }>('/v1/regions/search', params)
    }
  }
})
