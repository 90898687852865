export enum FilterType {
	Buy = 'buy',
	Rent = 'rent',
	RentDaily = 'rent_daily',
	RentHotel = 'rent_hotel'
}

export enum FilterTabType {
	//Grid,
	List,
	Map
}